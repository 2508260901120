<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row" style="background-color: #e30613; border-radius: 0.5%">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img
                src="@/assets/images/logo/redWhiteLogo.png"
                width="250px"
                alt="register"
                class="mx-auto"
              />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                  <div class="mt-2">
                    <vs-alert
                      id="alert"
                      v-if="this.changeError === false"
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="success"
                    >{{ message }}</vs-alert>
                    <vs-alert
                      v-else
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="danger"
                    >{{ message }}</vs-alert>
                  </div>
                </div>
                <form>
                  <vs-input
                    :success="
                      !errors.first('password') &&
                        user.password != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('password') ? true : false"
                    name="password"
                    :danger-text="errors.first('password')"
                    label="New Password"
                    class="w-full mb-5"
                    v-validate="{
                    required: true,
                    min: 8,
                    regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/
                  }"
                    ref="password"
                    data-vv-as="new password"
                    icon-no-border
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-model="user.password"
                    :icon="passwordIcon"
                    :type="passwordType"
                    @click.native="showHidePassword($event)"
                  />

                  <vs-input
                    :success="
                      !errors.first('confirmPassword') &&
                        user.confirmPassword != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('confirmPassword') ? true : false"
                    name="confirmPassword"
                    data-vv-as="confirm password"
                    :danger-text="errors.first('confirmPassword')"
                    label="Confirm Password"
                    class="w-full mb-5"
                    v-validate="'required|confirmed:password'"
                    icon-no-border
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-model="user.confirmPassword"
                    :icon="confirmPasswordIcon"
                    :type="confirmPasswordType"
                    @click.native="showHideConfirmPassword($event)"
                  />
                </form>

                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <!--                                    <vs-button type="border" to="/pages/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Go Back To Login</vs-button>-->
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateForm"
                    @click="resetPassword"
                  >Reset</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    password: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The confirmation password do not match",
    },
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      user: {
        password: "",
        confirmPassword: "",
        token: this.$route.params.token,
      },
      showDismissibleAlert: false,
      message: "",
      changeError: false,
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      confirmPasswordType: "password",
      confirmPasswordIcon: "icon icon-eye-off",
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.password != "" &&
        this.user.password == this.user.confirmPassword
      );
    },
  },
  methods: {
    ...mapActions("users", ["resetPassword"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    resetPassword() {
      const payload = {
        userDetails: this.user,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("auth/resetPassword", payload)
            .then((response) => {
              this.$vs.loading.close();
              localStorage.clear();
              this.$router.push("/success");
            })
            .catch((error) => {
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }
      });
    },
  },
};
</script>
